import React, { useEffect } from 'react';
import { ChakraProvider, Box, Text, Center } from '@chakra-ui/react';

const App = () => {
  useEffect(() => {
    // 新しいドメインにリダイレクト
    window.location.href = "https://ideaflowbank.com";
  }, []);

  return (
    <ChakraProvider>
      <Center height="100vh">
        <Box textAlign="center">
          <Text fontSize="2xl">Redirecting to the new domain...</Text>
        </Box>
      </Center>
    </ChakraProvider>
  );
}

export default App;


